import React from "react";

import homeImage from "../images/home_image.jpg";
import homeImageWEBP from "../images/home_image.webp";
import contact from "../images/contact.jpeg";
import contactWEBP from "../images/contact.webp";
import despre from "../images/despre.jpg";
import despreWEBP from "../images/despre.webp";
import whatsapp from "../images/whatsapp.png";

import Image from "./Image";
import Servicii from "./Servicii";
function Home() {
  return (
    <div className="container">
      <nav className="navbar">
        <div className="name">FrigoBestExpres</div>
        <a href="#despre" className="item">
          Despre
        </a>
        <a href="#servicii" className="item">
          Servicii
        </a>
        <a href="#contact" className="item">
          Contact
        </a>
      </nav>

      <header className="hero">
        {/* <img src={home_image} alt="home_image" /> */}
        <Image
          src={homeImageWEBP}
          fallback={homeImage}
          alt="Reparatii frigidere Cluj-Napoca"
          className="coverImg"
        />
        <div className="textContainer">
          <h1>Reparații frigidere Cluj</h1>

          <div className="ctas">
            {/* <a href="#contact">
            <button>Contact</button>
          </a> */}
            <a className={"telephone"} href="tel:+40742131064">
              <button>Sună acum</button>
            </a>
            <a
              className={"whatsapp"}
              aria-label="Chat on WhatsApp"
              href="https://wa.me/+40742131064"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Chat on WhatsApp" src={whatsapp} />
            </a>
          </div>
        </div>
      </header>

      <section id="despre" className="section despre">
        <div className="container">
          <ul className="text">
            <h2>Service Arctic Profesional Cluj-Napoca</h2>
            <li>
              Experiență vastă, specializați în repararea{" "}
              <strong>frigiderelor și congelatoarelor</strong>.
            </li>
            <li>
              Oferim servicii rapide și eficiente, cu peste <b>25 ani</b> de
              experiență în domeniu.
            </li>
            <li>
              Oferim garanție extinsă de <b>3 ani</b> pentru orice intervenție.
            </li>
            <label>
              Disponibili și pentru deplasări în <b>alte localități</b>, direct
              la domiciliul tău.
            </label>
          </ul>
          {/* <img src={despre} alt="despre" /> */}
          <Image src={despreWEBP} fallback={despre} alt="Despre noi" />
        </div>
      </section>

      <Servicii />

      <footer id="contact" className="section contact">
        {/* <img src={contact} alt="contact" /> */}
        <Image
          src={contactWEBP}
          fallback={contact}
          alt="Contacteaza-ne acum!"
          className="contactBackground"
        />
        <div className="cover" />

        <div className="content">
          <div className="text">
            <h1>Contact</h1>
            <p>
              <b>Cristi Cornici</b>
            </p>
            <p>
              Telefon <b>074 213 1064</b> / <b>0722 921 245</b>
            </p>
          </div>
          <a className={"telephone"} href="tel:074 213 1064">
            <button>Sună acum</button>
          </a>
          <a
            className={"whatsapp"}
            aria-label="Chat on WhatsApp"
            href="https://wa.me/+40742131064"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Chat on WhatsApp" src={whatsapp} />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
