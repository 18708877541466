import * as React from "react";
import Image from "./Image";

const Servicii = () => {
  // Automatically import all files matching the pattern
  const images = require.context("../images", false, /brand\d+\.png$/);

  // Load images into an array
  const imageList = images.keys().map((imagePath) => images(imagePath));

  return (
    <section className={"servicii"} id="servicii">
      <div className={"container"}>
        {imageList.map((src, idx) => (
          <Image src={src} alt={"Brand"} />
        ))}
      </div>
      <div className={"text"}>
        Reparăm congelatoare și combine frigorifice Arctic Ardo, Ariston, Beko,
        Bosch, Electrolux, Indesit, Gorenje, Whirlpool, Zanussi și alte modele.
      </div>
      <ul>
        <li>
          🛠️ <b>Diagnosticare rapidă și precisă</b> a defectelor (
          <b>termostat</b>, <b>compresor</b>, <b>scurgeri freon</b>, probleme{" "}
          <b>electronice</b>).
        </li>
        <li>
          ⚙️ Înlocuirea și repararea <b>pieselor originale</b>, garantând{" "}
          <b>fiabilitate</b> și <b>performanță îndelungată</b>.
        </li>
        <li>
          ❄️ Încărcare și verificare <b>agent frigorific (freon)</b>.
        </li>
        <li>
          📞 <b>Consultanță gratuită telefonică</b> privind problemele întâlnite
          la aparatul tău frigorific.
        </li>
        <li>
          🚨 Disponibilitate pentru intervenții în regim de <b>urgență</b> –
          intervenim rapid, chiar și în <b>weekend</b>.
        </li>
        <li>
          💡 <b>Sfaturi practice</b> privind <b>întreținerea corectă</b> a
          aparatului frigorific pentru a-i prelungi <b>durata de viață</b>.
        </li>
        <li>
          📅 <b>Flexibilitate în programarea intervenției</b> – ne adaptăm după
          programul tău.
        </li>
      </ul>
    </section>
  );
};

export default Servicii;
